import { Component, HostBinding, Input, OnChanges, OnDestroy } from '@angular/core';
import { IconComponent } from '@core/components/icon/icon.component';
import { LoaderComponent } from '@core/components/states/loader/loader.component';
import { CommonModule } from '@angular/common';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'pd-overlay-loader',
  standalone: true,
  imports: [IconComponent, LoaderComponent, CommonModule],
  template: `
    <ng-container *ngIf="!hideLoader">
      <pd-loader [loading]="true"></pd-loader>
      <div
        class="absolute bottom-0 left-0 right-0 top-0 z-10 flex items-center justify-center bg-gray-200 opacity-60 dark:bg-gray-600"></div>
    </ng-container>
  `,
  styles: ``,
})
export class OverlayLoaderComponent implements OnChanges, OnDestroy {
  @HostBinding('class')
  public classes = 'absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center';
  @Input()
  public loading: boolean | Observable<any> = true;
  @HostBinding('class.hidden')
  protected hideLoader = false;
  private subscription: Subscription = null;

  public ngOnChanges(): void {
    this.determineLoaderState();
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private determineLoaderState(): void {
    if (this.loading instanceof Observable) {
      this.hideLoader = false;
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.subscription = this.loading.subscribe({
        next: () => {
          this.hideLoader = true;
        },
        error: () => {
          this.hideLoader = true;
        },
      });
    } else {
      this.hideLoader = !this.loading;
    }
  }
}
